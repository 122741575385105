import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import HeaderImage from "../assets/img/new/tlo-serch-01.webp"

import oko from "../assets/img/new/eye.svg"
import oko_bg from "../assets/img/new/oko_bg.svg"
import target from "../assets/img/new/target.svg"
import target_bg from "../assets/img/new/target_bg.svg"
import money from "../assets/img/new/money.svg"
import money_bg from "../assets/img/new/money_bg.svg"

import trafic from "../assets/img/new/dedykowany-opiekun.svg"
import trafic_bg from "../assets/img/ikonki/strony/dedykowany-opiekun-znak.webp"
import chart from "../assets/img/new/monitoring-fraz.svg"
import chart_bg from "../assets/img/ikonki/strony/monitoring-fraz-znak.webp"
import report from "../assets/img/new/raport.svg"
import report_bg from "../assets/img/ikonki/strony/raport-znak.webp"

import content from "../assets/img/old/grafika-8.webp"
import optimize from "../assets/img/old/grafika-10.webp"
import optimize2 from "../assets/img/old/grafika-9.webp"
import link_building from "../assets/img/old/grafika-14.webp"
import stats from "../assets/img/old/grafika-12.webp"

import footer_bg from "../assets/img/new/tlo-konsultacje-03-4.webp"



import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const WebsitePositioning = () => {
  
  const { t } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage("en")
  }, [])

  return (
    <Layout
      seo={{
        title: "Website positioning • Have your website optimised by WeNet",
        description:
          "Use the website positioning service • We are specialists in website positioning - leave it to us!",
        hreflangs: {
          pl: "pozycjonowanie-stron/",
          en: "en/website-positioning/",
          de: "de/positionierung-von-webseiten/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Home",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/de/website-positioning/",
                name: t("seo-title-website-positioning"),
              },
            },
          ],
        },
      }}
    >
      <div
        className="hero-header-page"
        style={{ backgroundImage: `url("${HeaderImage}")` }}
      >
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-positioning"),
                text: t("link-text-positioning"),
              },
            ]}
          />
        </div>
        <h1 style={{ marginTop: "40px" }}>Website positioning</h1>
        <p style={{ marginBottom: "40px" }}>
          Not sure where to start with the complex optimisation process?
          <br />
          We can help you! Leave the matters of your website in our hands.
          <br />
          We are specialists in website positioning – we will do it for you!
        </p>
      </div>

      <section className="standard-section">
        <div className="container text-center">
          <h2 style={{ fontSize: "36px" }}>
            What are <strong>the benefits</strong> of website positioning?
          </h2>
        </div>
      </section>

      <section className="section-intro no-padding-top no-padding-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img
                    src={oko}
                    alt="Lepsza widoczność serwisu"
                    style={{ marginBottom: "15px", marginTop: "10px" }}
                  />
                </div>
                <h3>Improved visibility of the website</h3>
                <p>
                  you gain a higher search engine position and competitive
                  advantage.
                </p>
                <img src={oko_bg} className="bg-icon" alt="card background" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img
                    src={target}
                    alt="Dostęp do większej liczby użytkowników"
                  />
                </div>
                <h3>Access to more users</h3>
                <p>more customers visit your website, and you build trust.</p>
                <img
                  src={target_bg}
                  className="bg-icon"
                  alt="card background"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={money} alt="Gotowe wskazówki" />
                </div>
                <h3>Profit for the company and savings</h3>
                <p>
                  the investment in positioning pays off, and you can count on
                  long-term results.
                </p>
                <img src={money_bg} className="bg-icon" alt="card background" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="standard-section">
        <div className="container">
          <h2 className="text-center" style={{ fontSize: "36px" }}>
            What do our <strong>positioning services involve?</strong>
          </h2>
        </div>
      </section>

      <section className="text-image image-right">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Content optimisation</strong>
            </h2>
            <ul>
              <li>
                we perform a detailed analysis of texts, including key phrases,
              </li>
              <li>
                optimise content, metadata, H1, H2 and H3 headers and internal
                linking,
              </li>
              <li>check the website for content copy, </li>
              <li>
                eliminate content cannibalisation of subpages of the website{" "}
              </li>
              <li>analyse the internal linking of the website.</li>
            </ul>
          </div>
        </div>
        <div
          className="half d-none d-lg-block"
          style={{
            background: `url(${content}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="text-image image-left">
        <div
          className="half"
          style={{
            background: `url(${optimize}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Technical optimisation</strong>
            </h2>
            <ul>
              <li>we ensure the correct structure of the website, </li>
              <li>remove SEO-unfriendly techniques, </li>
              <li>check URL addresses to make them search engine friendly,</li>
              <li>
                carry out a website speed test and check its responsiveness,
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="text-image image-right reverse-flex">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Technical optimisation, cont.</strong>
            </h2>
            <ul>
              <li>
                improve current redirects or add new search engine friendly
                ones.
              </li>
              <li>
                we ensure that graphics have the right attributes and load
                faster,
              </li>
              <li>
                implement on the page the iframe with the company's location on
                Google Maps,
              </li>
              <li>
                fix errors that hinder or prevent the indexation of the site or
                its part.
              </li>
            </ul>
          </div>
        </div>
        <div
          className="half"
          style={{
            background: `url(${optimize2}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="text-image image-left">
        <div
          className="half"
          style={{
            background: `url(${link_building}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Link Building</strong>
            </h2>
            <ul>
              <li>
                we write and publish content marketing articles on quality
                websites,
              </li>
              <li>
                set up business pages in significant company catalogues and
                industry websites linking to the client's website
              </li>
              <li>create or update Google My Business and add entries,</li>
              <li>build the company's image on the web by creating entries,</li>
              <li>submit the company into SEO catalogues,</li>
              <li>set up, configure and maintain a blog,</li>
              <li>
                set up a fan page and manage communication in social media,
              </li>
              <li>install add-ons for your accounts on social media.</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="text-image image-right reverse-flex">
        <div className="half">
          <div className="content">
            <h2 style={{ fontSize: "30px" }}>
              <strong>Statistics</strong>
            </h2>
            <ul>
              <li>we add the website to Google Search Console,</li>
              <li>add the website to Google Analytics,</li>
              <li>analyse the website’s statistics.</li>
              <li>check the position of key phrases,</li>
              <li>check the website's visibility on Google.</li>
            </ul>
          </div>
        </div>
        <div
          className="half"
          style={{
            background: `url(${stats}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </section>

      <section className="section-intro">
        <div className="container">
          <div className="row">
            <div className="row-md-12">
              <h2 style={{ fontSize: "36px" }}>
                <strong>We ensure:</strong>
              </h2>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={trafic} alt="Dedicated mentor" />
                </div>
                <h3>Dedicated mentor</h3>
                <ul>
                  <li>they will monitor the results of your website,</li>
                  <li>
                    be at your disposal and provide explanations every day.
                  </li>
                </ul>
                <img src={trafic_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={chart} alt="Phrase and visibility monitoring" />
                </div>
                <h3>Phrase and visibility monitoring</h3>
                <ul>
                  <li>you can access detailed data,</li>
                  <li>you can follow the results of our work.</li>
                </ul>
                <img src={chart_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="icon">
                  <img src={report} alt="Comiesięczny raport" />
                </div>
                <h3>Monthly report</h3>
                <ul>
                  <li>
                    here, you will find key data on the progress of our
                    activities,
                  </li>
                  <li>you have complete control over the entire process.</li>
                </ul>
                <img src={report_bg} className="bg-icon" alt="Tło karty" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="outro"
        style={{
          backgroundImage: `url(${footer_bg})`,
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <h3>
                Use our SEO offer and have optimisation carried out by
                specialists from{" "}
                <span style={{ display: "inline-block" }}>WeNet – boost</span>{" "}
                the position of your website!
              </h3>

              <Link to={t("link-url-contact")} className="btn btn-primary">
                I want a free quotation for optimisation
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default WebsitePositioning
